@keyframes moveIn {
  0% {
    transform: translateX(100%);
  }
  60% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes moveOut {
  0% {
    transform: translateX(0);
  }
  60% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  85% {
    transform: scale(0.9);
  }
  95% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes zoomOut {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
